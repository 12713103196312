import { css } from '@emotion/react';

const styles = css`
  @import url('/fonts/inter/inter.css');
  html {
    font-family: 'Inter', sans-serif;
  }
  @supports (font-variation-settings: normal) {
    html {
      font-family: 'Inter var', sans-serif;
      font-feature-settings: 'liga', 'calt', 'ss01', 'cv05', 'cv06', 'cv08', 'cv10', 'cv11';
    }
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    direction: ltr;
    margin: 0;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
  }
`;

export default styles;
