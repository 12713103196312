import App, { AppProps } from 'next/app'
import * as Sentry from '@sentry/node'
import { CacheProvider, Global } from '@emotion/react'
import { cache } from '@emotion/css'
import { ThemeProvider } from 'theme-ui'

import styles from 'styles/global'
import theme from 'styles/theme.json'

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
})

type Props = AppProps & {
  err?: Error
}

class MyApp extends App<Props> {
  render() {
    const { Component, pageProps, err } = this.props
    return (
      <CacheProvider value={cache}>
        <Global styles={styles} />
        <ThemeProvider theme={theme}>
          {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </CacheProvider>
    )
  }
}

export default MyApp
